import { Component, EngagementTest, WorkshopSidebar } from "@components";
import { Container, StyledDashboard } from "@util/standard";
import { BlocksContent, Drawer, Image, MentorPopup } from "@global";
import { PageProps, graphql, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

import { PageContext } from "@util/types";
import { Query } from "@graphql-types";
import { Script } from "gatsby";
import { assets, colors } from "@util/constants";
import { useReturnCompleted } from "@state/logicHooks";
import { useStore } from "@state/store";
import { logoutUser } from "@auth/getFirebase";
import { toPlainText } from "@portabletext/react";
import { getCookieValue, setCookie } from "@util/helper";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const deployDashboard = Boolean(process.env.GATSBY_DASHBOARD == "true");

export default function PageTemplate({ data, pageContext }: Props) {
  const [expanded, setExpanded] = useState(false);

  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [completeLoaded, setCompleteLoaded] = useState(false);

  if (!data.sanityPage) return null;
  const {
    blocks,
    backgroundOptions,
    backgroundColour,
    imageWithMeta,
    _id,
    mentorPopups,
    tourSteps,
  } = data.sanityPage;

  const {
    globalQuiz,
    setGlobalQuizz,
    setGlobalQuizOutcomes,
    user,
    setCurrentPagePath,
    setGlobalMentorPopups,
    globalMentorPopups,
  } = useStore();

  const { sectionQuizzes, workshops, slug } = pageContext;

  const { setAllSanityWorkshops, sortedCompletedWorkshops } = useReturnCompleted();

  const steps =
    tourSteps &&
    tourSteps.map(step => {
      if (step?.content == null || step?.targetName == null) return;
      return {
        target: `#${step?.targetName}`,
        content: toPlainText(step?.content._rawBlockContent),
        disableBeacon: true,
        openDrawer: step.openDrawer ?? false,
        delayStart: step.delayStart ?? null,
      };
    });

  const handlePageLoad = async () => {
    if (user?.uid || !deployDashboard) {
      //Check cookie first

      // const loginCookieExists = getCookieValue("101LaunchpadLogin");
      // if (loginCookieExists == false && deployDashboard == true) {
      //   console.log("logout");
      //   return setTimeout(() => {
      //     logoutUser();
      //   }, 1000);
      // }

      if (slug) setCurrentPagePath(slug);
      setAllSanityWorkshops(workshops);
      if (sectionQuizzes) {
        let globalQuizArray: any = [];
        let outcomesArray: any = [];

        sectionQuizzes.forEach(quiz => {
          //Check for existing quiz
          const exists = globalQuiz && globalQuiz?.find(q => q.id === quiz._id);
          if (!exists) {
            const removeDrafts = quiz._id.replace("draft.", "");

            const globalForm = {
              id: removeDrafts,
              title: quiz.title,
              questions: [],
              initialScoreValue: quiz.initialScoreValue,
              scoringOption: quiz.scoringOption,
            };

            globalQuizArray.push(globalForm);
          }

          //save outcomes
          const outcomes = quiz.outcomes;
          if (outcomes) {
            const outcomeObj = {
              id: quiz._id,
              outcomes: outcomes,
            };
            outcomesArray.push(outcomeObj);
          }
        });
        if (globalQuizArray.length > 0) {
          setGlobalQuizz(globalQuizArray);
        }
        if (outcomesArray.length > 0) {
          setGlobalQuizOutcomes(outcomesArray);
        }
      }

      //Handle mentor popup

      //Handle tour step
      if (steps && steps.length > 0) {
        const hasCookie = getCookieValue(`tour-${slug}`);
        const hasMentorPopup = globalMentorPopups?.includes(pageContext?.pageId);

        if (!hasCookie && !hasMentorPopup) {
          if (steps[0]?.delayStart) {
            setTimeout(() => {
              setRunTour(true);
            }, steps[0]?.delayStart);
          } else {
            setTimeout(() => {
              setRunTour(true);
            }, 1000);
          }
        }
      }
    } else {
      navigate("/");
    }
  };

  const handleJoyrideCallback = data => {
    const { status, index, type, action } = data;

    if ([EVENTS.STEP_AFTER].includes(type)) {
      // Update state to advance the tour
      if (data.step.openDrawer && expanded === false) {
        setRunTour(false);
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        setExpanded(true);
        setTimeout(() => {
          setRunTour(true);
        }, 1000);
      } else {
        setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      }
    } else if ([STATUS.FINISHED].includes(status)) {
      setRunTour(false);
      setCookie(`tour-${slug}`, "true", 1);
      if (expanded === true) {
        setExpanded(false);
      }
    }
  };

  useEffect(() => {
    handlePageLoad();
  }, []);

  useEffect(() => {
    if (sortedCompletedWorkshops && !completeLoaded) {
      // const isAccessible = isPageAccessible(_id);
      // console.log("isAccessible", isAccessible);

      // if (!isAccessible && completed) {
      //   navigate("/");
      // }
      setCompleteLoaded(true);
    }
  }, [sortedCompletedWorkshops]);

  return (
    <>
      <Script
        id="sib"
        dangerouslySetInnerHTML={{
          __html: ` (function(d, w, c) {
        w.SibConversationsID = '63be052fd44be91a1b24e842';
        w[c] = w[c] || function() {
            (w[c].q = w[c].q || []).push(arguments);
        };
        var s = d.createElement('script');
        s.async = true;
        s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
        if (d.head) d.head.appendChild(s);
    })(document, window, 'SibConversations');`,
        }}
      />
      {user?.isAdmin && <EngagementTest user={user} pageContext={pageContext} />}
      {deployDashboard ? (
        <StyledDashboard>
          <div className="hamburger">
            <div className="hamburger-wrapper" id="sidebar-arrow">
              <img
                src={assets.accordionActiveOrange}
                onClick={() => {
                  setExpanded(!expanded);
                }}
              />
            </div>
            <Drawer
              onClose={() => {
                setExpanded(false);
              }}
              visible={expanded}
              placement="left"
              width="fit-conent"
            >
              <WorkshopSidebar
                expanded={expanded}
                setExpanded={setExpanded}
                workshopContext={pageContext.workshopContext}
                currentPage={_id}
                sortedCompletedWorkshops={sortedCompletedWorkshops}
              />
            </Drawer>
          </div>

          <Container
            display="flex"
            flexDirection="column"
            sanityBackgroundColor={
              backgroundOptions && backgroundOptions == "colour" && backgroundColour?.value
            }
            position="relative"
            width="100%"
          >
            {backgroundOptions == "image" && imageWithMeta && (
              <Image data={imageWithMeta} isBackground />
            )}
            <div style={{ zIndex: 10, position: "relative" }}>
              {blocks &&
                blocks.map((component, index) => {
                  if (component == null) return null;
                  return <Component key={(component._type, index)} data={component} />;
                })}
            </div>
            <MentorPopup data={mentorPopups} page={_id} />
          </Container>
        </StyledDashboard>
      ) : (
        <Container
          display="flex"
          flexDirection="column"
          sanityBackgroundColor={
            backgroundOptions && backgroundOptions == "colour" && backgroundColour?.value
          }
          position="relative"
        >
          {backgroundOptions == "image" && imageWithMeta && (
            <Image data={imageWithMeta} isBackground />
          )}
          <div style={{ zIndex: 10, position: "relative" }}>
            {blocks &&
              blocks.map((component, index) => {
                if (component == null) return null;
                return <Component key={(component._type, index)} data={component} />;
              })}
          </div>
        </Container>
      )}

      {steps && (
        <Joyride
          steps={steps}
          run={runTour}
          hideCloseButton
          continuous
          locale={{
            next: "Got it",
            last: "Got it",
          }}
          stepIndex={stepIndex}
          styles={{
            buttonNext: {
              backgroundColor: colors.orange,
            },
            buttonBack: {
              color: colors.primary,
            },
          }}
          callback={handleJoyrideCallback}
        />
      )}
    </>
  );
}

export const query = graphql`
  query PageQuery($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      mentorPopups {
        ...sanityMentorPopup
      }
      _id
      blocks {
        ...sanityColumnBlock
      }
      backgroundOptions
      backgroundColour {
        ...sanityColorlist
      }
      imageWithMeta {
        ...sanityImageFullWidthNoCompressionNoCompression
      }
      tourSteps {
        _key
        targetName
        openDrawer
        delayStart
        content {
          ...sanityBlockContent
        }
      }
    }
  }
`;
